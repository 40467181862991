.report-page {
  .margin-top-0 {
    margin-top: 0mm !important;
  }

  .margin-top-4 {
    margin-top: 4mm !important;
  }

  .margin-top-5 {
    margin-top: 5mm !important;
  }

  .margin-top-7 {
    margin-top: 7mm !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .margin-bottom-2 {
    margin-bottom: 2mm !important;
  }

  .margin-bottom-3 {
    margin-bottom: 3mm !important;
  }

  .margin-bottom-4 {
    margin-bottom: 4mm !important;
  }

  .margin-bottom-5 {
    margin-bottom: 5mm !important;
  }

  .margin-bottom-6 {
    margin-bottom: 6mm !important;
  }

  .margin-bottom-7 {
    margin-bottom: 7mm !important;
  }

  .margin-bottom-8 {
    margin-bottom: 8mm !important;
  }

  .margin-bottom-9 {
    margin-bottom: 9mm !important;
  }

  .margin-bottom-10 {
    margin-bottom: 10mm !important;
  }

  .margin-bottom-11 {
    margin-bottom: 11mm !important;
  }

  .margin-bottom-12 {
    margin-bottom: 12mm !important;
  }

  .margin-bottom-13 {
    margin-bottom: 13mm !important;
  }

  .margin-bottom-18 {
    margin-bottom: 18mm !important;
  }

  .margin-bottom-20 {
    margin-bottom: 20mm !important;
  }

  .margin-bottom-25 {
    margin-bottom: 25mm !important;
  }

  .margin-bottom-90 {
    margin-bottom: 90mm !important;
  }
}
