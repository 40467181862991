.flash {
  @include callout($success-color);
  font-weight: bold;

  &--warning {
    @include callout($warning-color);
  }

  &--alert {
    @include callout($alert-color);
  }
}
