.report-row {
  @include xy-grid(horizontal, false);
  &--column {
    @include xy-grid(vertical, true);
  }
}

.report-block {
  @include xy-cell-static(full, false);
  word-wrap: break-word;

  &--cr {
    text-align: right;
    width: 10mm;
  }
}

@media print {
  @page {
    margin: 15mm 18mm
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  main {
   padding: 0;
  }
  .page-break {
    clear: both;
    page-break-after: always;
  }
}

.notification {
  background-color: $marlborough-orange-lint !important;
  border-left-color: $marlborough-orange !important;
  border-left-style: solid;
  border-left-width: 5px;
  padding: 0 20px;
}

.text-align-right {
  text-align: right;
}

.padding-right{
  padding-right: 1rem;
}

hr.title-line {
  border-bottom: 2px solid $marlborough-blue;
  border-top: 0;
  margin: 2mm auto;
}

hr.sub-title-line {
  border-bottom: 1px solid $marlborough-blue;
  border-top: 0;
  margin: 2mm auto;
}

hr.bottom-border-line {
  border-bottom: 1px solid $marlborough-blue;
  border-top: 0;
  margin: 2mm auto;
}

.double-line {
  height: 6px;
  border-top: 1px solid $marlborough-blue;
  border-bottom: 1px solid $marlborough-blue;
}

.print-logo {
  width: 60mm;
}
