.information-list {
  @include flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;

  &--horizontal {
    @include breakpoint(large) {
      flex-wrap: wrap;
      height: 50px;

      .information-list__value {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: $vampire-black;
    flex: 1 1 auto;
    font-size: 12px;
    font-weight: normal;
  }

  &__value {
    color: $raisin-black;
    font-size: 16px;
    margin-bottom: $global-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
