.navigation {
  @include xy-grid-container;
  background-color: $primary-color;
  max-width: $global-width;
  padding: $global-padding * 2 $global-padding;

  @include breakpoint(medium down) {
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    transition: 0.5s ease;
    width: 320px;
    z-index: 10;

    &--open {
      left: 0;
    }
  }

  @include breakpoint(large) {
    background-color: $primary-color;
    padding: 0 $global-padding;
  }

  &__list {
    @include menu-base;
    @include menu-direction(vertical);
    margin-bottom: 2 * $global-margin;

    @include breakpoint(large) {
      @include flex-align(right, middle);
      @include menu-direction(horizontal);
      margin-bottom: 0;
    }

    li {
      flex: 1 1 auto;

      img {
        max-width: 12rem;
        width: 100%;
      }

      &:first-child {
        margin-bottom: 2 * $global-margin;

        @include breakpoint(large) {
          margin-bottom: 0;
          margin-right: auto;
        }
      }

      input {
        margin: $global-margin;
      }
    }

    a {
      color: $white;
      font-size: 12px;
    }
  }

  &__search-box {
    background-color: $dark-gray;
    border: 0;
    color: $white;
    font-size: 12px;
    height: 30px;

    &:focus {
      background-color: $dark-gray;
    }

    @include breakpoint(large) {
      margin-right: 50px;
      width: 300px;
    }
  }

  &__button {
    &--close {
      @extend .close-button;

      @include breakpoint(large) {
        display: none;
      }
    }

    &--toggle {
      @extend .menu-icon;
    }
  }
}
