body,
html {
  min-height: 100vh;
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  flex-shrink: 1;
}

main {
  @include xy-grid-container;
  flex: 1 0 auto;
  width: 100%;
}

footer {
  flex-shrink: 1;
}

.row {
  @include xy-grid(horizontal, true);
  @include xy-gutters(2 * $global-margin, margin, right left, true);

  @include breakpoint(large) {
    @include xy-grid(horizontal, false);
  }

  &--narrow {
    margin: 0 auto $global-padding;
    max-width: 460px;
  }

  &--spaced {
    margin-bottom: $global-padding;
  }
}

.block {
  @include xy-cell-static(full, true, 2 * $global-margin, margin);

  &--shrink {
    @include breakpoint(medium) {
      @include xy-cell-static(shrink, true, 2 * $global-margin, margin);
    }
  }

  &--right {
    @include breakpoint(large) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &--horizontal {
    @include flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--column {
    @include flex;
    flex-direction: column;
  }

  &--spaced {
    margin-bottom: $global-padding;
  }
}
