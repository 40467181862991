.list {
  @include flex;
  @include flex-direction(column);
  margin-left: -1 * $global-padding / 4;
  margin-right: -1 * $global-padding / 4;

  &--striped {
    .list__item {
      &:nth-child(even) {
        background-color: $gray-blue;
      }

      &:nth-child(odd) {
        background-color: $white;
      }
    }
  }

  &__item {
    @include flex;
    align-items: center;
    background-color: $light-blue;
    margin-bottom: $global-margin;

    &--heading {
      background-color: $white;
      margin-bottom: 0;
    }

    &--bold {
      font-weight: bold;
    }

    &--no-space {
      margin-bottom: 0;
    }
  }

  &__attribute {
    @include flex;
    flex: 1 0 12%;
    margin-bottom: 0;
    padding: $global-padding / 2;

    &:last-child {
      justify-content: flex-end;
    }

    &--wide {
      flex-basis: 21%;
    }

    &--left-justified {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }

  &__button {
    @extend .button;
    margin: 0 0 0 $global-margin;
  }
}
