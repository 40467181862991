
@font-face {
  font-family: "Arial Reg";
  src: url("../fonts/Arial.ttf");
  src: url("../fonts/Arial.ttf") format("truetype"),
}


@font-face {
  font-family: "Proxima Nova Reg";
  src: url("../fonts/Proxima_Nova_Regular.ttf");
  src: url("../fonts/Proxima_Nova_Regular.ttf") format("truetype"),
}
