.button {
  min-width: 80px;
  white-space: nowrap;

  &--full-width {
    width: 100%;
  }

  .button-group--spaced & {
    margin: 0 5%;
  }
}

.button-group {
  flex: wrap;

  &--spaced {
    justify-content: center;
    margin: $global-margin 0 2 * $global-margin;
  }

  &--reversed {
    flex-direction: row-reverse;
  }
}
