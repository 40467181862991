.table {

  th {
    font-weight: normal;
  }

  span {
    display: block;
    margin: $global-margin / 2 0;
    text-align: right;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--mathematical {
    tr {
      &:last-child {
        border-bottom: solid $black 2px;
        font-weight: bold;
      }
    }

    td {
      &:last-child {
        text-align: right;
      }
    }
  }
}
