.devise-form {
  background-color: $light-blue;
  border: 1px solid  $secondary-color;
  padding-bottom: $global-padding * 2;
  padding-left: $global-padding * 5;
  padding-right: $global-padding * 5;
  padding-top: $global-padding * 2;

  &__head {
    text-align: center;
  }

  &__wrapper {
    @include flex;
    @include flex-align(center, middle);
    min-height: 80vh;
  }
}
