.section {
  background-color: $light-blue;
  border: 0;
  padding: $global-padding;

  &__head {
    h2 {
      color: $raisin-black;
    }

    &--bold {
      h2 {
        font-weight: bold;
      }
    }
  }

  &__body {
    margin-top: $global-margin;

    & > div {
      width: 100%;
    }
  }

  &__footer {
    margin-top: $global-margin;

    &--right {
      @include flex;
      justify-content: flex-end;
    }
  }
}
