.report-page {
  h1 {
    font-family: "Proxima Nova Reg", sans-serif;
    font-style: normal;
    font-weight: 600;

    font-size: 20px;
    line-height: 24px;

    color: $report-font-color !important;
    margin-bottom: 0;
  }

  h2 {
    font-family: "Proxima Nova Reg", sans-serif;
    font-style: normal;
    font-weight: 600;

    font-size: 16px;
    line-height: 20px;

    color: $report-font-color !important;
    margin-bottom: 0;
  }

  h3 {
    font-family: "Proxima Nova Reg", sans-serif;
    font-style: normal;
    font-weight: 600;

    font-size: 14px;
    line-height: 18px;

    color: $report-font-color !important;
    margin-bottom: 0;
  }

  h4 {
    font-family: "Arial Reg", sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 10px;
    line-height: 12px;

    color: $report-font-color !important;
    margin-bottom: 0;
  }

  p {
    font-family: "Arial Reg", sans-serif;
    font-style: normal;
    font-weight: normal;

    font-size: 10px;
    line-height: 14px;

    color: $report-font-color !important;
    margin-bottom: 0;
  }

  .ps {
    font-family: "Arial Reg", sans-serif;
    font-style: normal;
    font-weight: normal;

    font-size: 9px;
    line-height: 11px;

    color: $report-font-color !important;
  }

  .link {
    font-weight: 700;
    color: $marlborough-blue !important;
  }
}
