.form {
  &__help-text {
    margin-bottom: $global-margin;
    position: relative;
    top: -1 * $global-margin;
  }

  &__input-group {
    @extend .input-group;
  }

  &__input-group-field {
    @extend .input-group-field;
    background-color: $input-background;
    border: 1px solid $medium-gray;
    padding: 6px;
  }

  &__input-group-button {
    > input {
      margin: 0;
    }
  }

  &__margin-top {
    margin-top: $global-margin;
  }
}
