.header {
  background-color: $primary-color;
  margin-bottom: 2 * $global-margin;

  &__bar {
    @extend .title-bar;

    @include breakpoint(large) {
      display: none;
    }

    &--left {
      flex: 1 0 auto;
    }

    &--right {
      flex: 0 1 auto;
    }
  }

  &__bar-title {
    @extend .title-bar-title;
  }
}
